import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import loadable from '@loadable/component'
import './styles.scss'

const Section = loadable(() => import('/src/components/Section'))
const SectionHeader = loadable(() => import('/src/components/SectionHeader'))

const Calls = () => {
    const section = {
        title: "But I don't want hundreds of calls..."
    }

    return (
        <Section className="c-calls">
            <div className="row">
                <div className="col-lg-8 offset-lg-2">
                    <SectionHeader data={section} variant="centered" />
                </div>
            </div>

            <div className="c-section__content">
                <div className="row">
                    <div className="col-lg-2 c-calls__image-column">
                        <StaticImage
                            src="../../images/illustrations/nocalls.png"
                            alt="Illustration of many with wallet and bank card"
                        />
                    </div>

                    <div className="col-lg-9 offset-lg-1">
                        <p>Ok, so how about just one phone call?</p>

                        <p>
                            Just one phone call is all it takes to potentially
                            save years of financial hardship for your family.
                        </p>

                        <p>
                            WE KNOW THAT YOU'RE BUSY. Honestly, we get it! We're
                            people too... with families, jobs, dogs to walk,
                            bills to pay, lawns to mow...
                        </p>

                        <p>
                            Most calls will take between 30-45 minutes, and then
                            that's it. You can take peace knowing that because
                            you spent 45 minutes on the phone, your loved ones
                            are protected, just in case something happens down
                            the line.
                        </p>
                    </div>
                </div>
            </div>
        </Section>
    )
}

export default Calls
